.blacklist-screen-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.blacklist-data-card {
  background-color: white;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: 6px;
}

.blacklist-data-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: var(--color-error-light);
  border-radius: 3px;
}

.blacklist-data-card-details {
  display: flex;
  flex-direction: column;
}

.blacklist-wrapper {
  background-color: white;
  padding: 1rem;
  border-radius: 6px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.blacklist-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: #00000005;
  padding: 0.5rem;
  border-radius: 6px;
}

.blacklist-header-actions-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.blacklist-table-wrapper {
  flex-grow: 1;
  background-color: #00000005;
  padding: 0.5rem;
  border-radius: 6px;
  height: 0;
  display: flex;
  flex-direction: column;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator.blacklist-table-column-separator {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.blacklist-table-column-headers {
  border-right: 1px solid #e5cccb;
  background-color: #fbd6d5;
}

.MuiDataGrid-root .MuiDataGrid-cell.blacklist-table-cell {
  border-right: 1px solid #e0e0e0;
}