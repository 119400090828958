:root {
  --color-primary-main: #675AC1;
  --color-primary-light: #F4F3FF;
  --color-primary-dark: #403685;
  --color-secondary-main: #112c34;
  --color-success-main: #4CAF50;
  --color-success-light: #E8F5E9;
  --color-success-dark: #46a249;
  --color-error-main: #E53935;
  --color-error-light: #FEEBEE;
  --color-warning-main: #EDA438;
  --color-warning-light: #FEF7EE;
  --color-info-main: #388DFF;
  --color-info-light: #E0F0FF;
  --color-info-dark: #1F53AF;
  --color-text-primary: #3D3D3D;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.close-dialog-icon-wrapper {
  position: absolute;
  top: 0;
  right: 0.5rem;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiDialogTitle-root.dialog-title-root {
  position: relative;
}

.MuiDialogTitle-root.dialog-title-root.primary {
  background-color: var(--color-primary-main);
  color: white;
}

.MuiDialogTitle-root.dialog-title-root.success {
  background-color: var(--color-success-main);
  color: white;
}

.MuiDialogTitle-root.dialog-title-root.error {
  background-color: var(--color-error-main);
  color: white;
}

.MuiDialogContent-root.dialog-content-root {
  padding-top: 1rem !important;
}

.MuiFormControl-root.MuiTextField-root.vehicle-number-input-root input {
  text-transform: uppercase;
}

.MuiFormControl-root.MuiTextField-root.vehicle-number-input-root ::-webkit-input-placeholder {
  text-transform: none;
}

.MuiFormControl-root.MuiTextField-root.vehicle-number-input-root :-moz-placeholder {
  text-transform: none;
}

.MuiFormControl-root.MuiTextField-root.vehicle-number-input-root ::-moz-placeholder {
  text-transform: none;
}

.MuiFormControl-root.MuiTextField-root.vehicle-number-input-root :-ms-input-placeholder {
  text-transform: none;
}

.MuiFormControl-root.MuiTextField-root.vehicle-number-input-root ::placeholder {
  text-transform: none;
}

/* Hide arrows on number input field */
/* For Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.fullscreen-evidence-image-section {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-evidence-image-wrapper {
  height: 90vh;
  max-width: 90vw;
  aspect-ratio: 16/9;
}

.table-column-header-dropdown-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.table-column-header-dropdown-wrapper.center {
  justify-content: center;
}

.MuiAlert-root.MuiAlert-filledError.notification-error {
  background-color: var(--color-error-light);
  border: 1px solid var(--color-error-main);
  color: var(--color-error-main);
}

.MuiAlert-root.MuiAlert-filledWarning.notification-warning {
  background-color: var(--color-warning-light);
  border: 1px solid var(--color-warning-main);
  color: var(--color-warning-main);
}

.MuiAlert-root.MuiAlert-filledSuccess.notification-success {
  background-color: var(--color-success-light);
  border: 1px solid var(--color-success-main);
  color: var(--color-success-main);
}

.MuiFormControl-root .MuiInputBase-root.MuiFilledInput-root.filled-input-root,
.MuiFormControl-root .MuiInputBase-root.MuiFilledInput-root.filled-input-root input {
  border-radius: 4px;
}

.MuiTablePagination-spacer.grid-pagination-spacer-custom {
  display: none;
}