.authorized-list-screen-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.authorized-list-data-card {
  background-color: white;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: 6px;
}

.authorized-list-data-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: var(--color-success-light);
  border-radius: 3px;
}

.authorized-list-data-card-details {
  display: flex;
  flex-direction: column;
}

.authorized-list-wrapper {
  background-color: white;
  padding: 1rem;
  border-radius: 6px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.authorized-list-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: #00000005;
  padding: 0.5rem;
  border-radius: 6px;
}

.authorized-list-header-actions-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.authorized-list-table-wrapper {
  flex-grow: 1;
  background-color: #00000005;
  padding: 0.5rem;
  border-radius: 6px;
  height: 0;
  display: flex;
  flex-direction: column;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator.authorized-list-table-column-separator {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.authorized-list-table-column-headers {
  background-color: #d7f3c3;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.authorized-list-table-column-headers:not(:last-child):not(:nth-last-child(2)) {
  border-right: 1px solid #c3ddb2;
}

.MuiDataGrid-root .MuiDataGrid-cell.authorized-list-table-cell:not(:last-child):not(:nth-last-child(2)) {
  border-right: 1px solid #e0e0e0;
}

.vehicles-list-wrapper {
  background-color: #FAFAFA;
  border-radius: 5px;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-new-vehicle-button {
  border: 1px dashed var(--color-primary-main);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  cursor: pointer;
}

.vehicles-list-details-wrapper {
  border: 1px solid var(--color-primary-main);
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}

.vehicle-item-input-wrapper {
  flex: 1;
}