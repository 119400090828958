.user-activity-log-screen-wrapper {
  flex-grow: 1;
  background-color: white;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.user-activity-log-screen-header {
  background-color: var(--color-primary-light);
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.user-activity-table-wrapper {
  flex-grow: 1;
  height: 0;
  display: flex;
  flex-direction: column;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.user-activity-table-column-header {
  background-color: #D7D1FF;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.user-activity-table-column-header:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  height: 60%;
  background-color: #00000026;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator.user-activity-table-column-separator {
  display: none;
}

.activity-detail-item {
  background-color: var(--color-primary-light);
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}