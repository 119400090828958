.log-type-selection-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  min-width: 200px;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  fill: white;
  background-color: var(--color-primary-main);
}

.log-type-selection-button.authorized {
  background-color: var(--color-success-main);
}

.log-type-selection-button.blacklisted {
  background-color: var(--color-error-main);
}

.log-type-selection-button.unauthorized {
  background-color: var(--color-warning-main);
}

.log-type-selection-button.visitor {
  background-color: var(--color-info-main);
}

.log-type-selection-button-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.MuiList-root.log-type-selection-dropdown-menu {
  padding: 0.25rem 0.5rem;
}

.MuiButtonBase-root.MuiMenuItem-root.log-type-selection-dropdown-menu-item {
  min-width: calc(200px - 1rem);
  font-weight: 500;
  margin: 0.25rem 0;
  border-radius: 5px;
  color: white;
  background-color: var(--color-primary-main);
}

.MuiButtonBase-root.MuiMenuItem-root.log-type-selection-dropdown-menu-item .MuiListItemIcon-root.log-type-selection-dropdown-menu-item-icon {
  color: white;
  fill: white;
}

.MuiButtonBase-root.MuiMenuItem-root.log-type-selection-dropdown-menu-item.authorized {
  background-color: var(--color-success-main);
}

.MuiButtonBase-root.MuiMenuItem-root.log-type-selection-dropdown-menu-item.blacklisted {
  background-color: var(--color-error-main);
}

.MuiButtonBase-root.MuiMenuItem-root.log-type-selection-dropdown-menu-item.unauthorized {
  background-color: var(--color-warning-main);
}

.MuiButtonBase-root.MuiMenuItem-root.log-type-selection-dropdown-menu-item.visitor {
  background-color: var(--color-info-main);
}