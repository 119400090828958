.device-camera-settings-screen-wrapper {
  flex-grow: 1;
  background-color: white;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.device-camera-settings-screen-header {
  background-color: var(--color-primary-light);
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.devices-table-wrapper {
  flex-grow: 1;
  height: 0;
  display: flex;
  flex-direction: column;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator.devices-table-column-separator {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.devices-table-column-header {
  background-color: #D7D1FF;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.devices-table-column-header:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  height: 60%;
  background-color: #00000026;
}

.devices-table-status-column-cell {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.devices-table-status-column-cell.Online {
  color: var(--color-success-main);
}

.devices-table-status-column-cell.Offline {
  color: var(--color-error-main);
}

.devices-table-global-ip-column-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
}

/* CONNECTED CAMERAS DIALOG */
.connected-cameras-dialog-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.device-details-header {
  width: 100%;
  background-color: var(--color-primary-light);
  padding: 0.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.device-name-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1.25rem 0.5rem 1rem;
  border: 1px solid var(--color-primary-main);
  border-radius: 4px;
  color: var(--color-primary-main);
}

.cameras-list-wrapper {
  flex-grow: 1;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-auto-rows: max-content;
  gap: 1rem;
}

.camera-details-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.camera-details-card-header {
  width: 100%;
  background-color: var(--color-primary-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 5px;
}

.camera-name-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border: 1px solid var(--color-primary-main);
  border-radius: 4px;
  color: var(--color-primary-main);
}

.camera-actions-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.camera-action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-main);
  border-radius: 4px;
  color: white;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.camera-sample-image img {
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
}