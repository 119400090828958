.time-range-selection-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  color: var(--color-primary-main);
  min-width: 250px;
  background-color: white;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--color-primary-main);
}

.time-range-selection-button-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.MuiMenuItem-root.time-range-selection-dropdown-menu-item {
  min-width: 250px;
  color: #000000DE;
  padding: 0.5rem 1rem;
  justify-content: center;
}

.MuiMenuItem-root.time-range-selection-dropdown-menu-item .MuiListItemIcon-root.time-range-selection-dropdown-menu-item-icon {
  color: #000000DE;
}

/* CUSTOM RANGE SELECTION DIALOG */
.date-range-toolbar-section-wrapper {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;
}

.date-range-inputs-wrapper {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}

.predefined-date-value-selection-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.predefined-date-value {
  width: 100%;
  background-color: #32116908;
  border: 1px solid var(--color-primary-main);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0;
  color: var(--color-primary-main);
  cursor: pointer;
}

.predefined-date-value.active {
  background-color: var(--color-primary-main);
  color: white;
}