.communication-channels-screen-wrapper {
  flex-grow: 1;
  background-color: white;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 0;
  overflow-y: auto;
}

.communication-channels-screen-actions-wrapper {
  width: 100%;
  padding: 0.5rem;
  background-color: #00000005;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .communication-channels-table-wrapper {
  flex-grow: 1;
  height: 0;
  display: flex;
  flex-direction: column;
} */

.MuiDataGrid-root .MuiDataGrid-columnSeparator.communication-channels-table-column-separator {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.communication-channels-table-column-header {
  background-color: #D7D1FF;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.communication-channels-table-column-header:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  height: 60%;
  background-color: #00000026;
}

.communication-channels-table-actions-column-cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overstay-alerts-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
}