.downtime-event-info-dialog-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.event-details-wrapper {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.event-details-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.event-details-card.success {
  background-color: var(--color-success-light);
}

.event-details-card.error {
  background-color: var(--color-error-light);
}

.event-details-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-details-card.success .event-details-card-icon {
  color: var(--color-success-main);
}

.event-details-card.error .event-details-card-icon {
  color: var(--color-error-main);
}

.MuiDataGrid-root.downtime-events-table-root {
  border-radius: 5px;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator.downtime-events-table-column-separator {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.downtime-events-table-column-header {
  background-color: #F4F3FF;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.downtime-events-table-column-header:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  height: 60%;
  background-color: #00000026;
}

.MuiDataGrid-root .MuiDataGrid-cell.Offline {
  background-color: #FEEBEE;
}

.event-details-date-wrapper {
  width: 100%;
  background-color: var(--color-primary-light);
  border-radius: 5px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.date-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  background-color: var(--color-primary-main);
  color: white;
  border-radius: 3px;
}