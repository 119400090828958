.MuiDataGrid-root .MuiDataGrid-cell.input-error {
  background-color: #FECDD2;
  border: 1px solid var(--color-error-main);
}

.MuiDataGrid-root .MuiDataGrid-cell.input-error:focus {
  outline-color: var(--color-error-main);
}

.MuiDataGrid-root .MuiDataGrid-row.row-input-error {
  outline: solid var(--color-error-main) 1px;
  border-left: 1px solid var(--color-error-main);
  border-right: 1px solid var(--color-error-main);
}

.MuiDataGrid-root .MuiDataGrid-cell.MuiDataGrid-cell--editing.input-error.number-plate input {
  text-transform: uppercase;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator.upload-data-validation-table-column-separator {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.upload-data-validation-table-column-header {
  background-color: #D7D1FF;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.upload-data-validation-table-column-header:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  height: 60%;
  background-color: #00000026;
}

.upload-summary-card {
  background-color: var(--color-primary-light);
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.upload-summary-card .upload-summary-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-main);
  fill: var(--color-primary-main);
}

.upload-summary-card.success {
  background-color: var(--color-success-light);
}

.upload-summary-card.success .upload-summary-card-icon {
  color: #43A047;
  fill: #43A047;
}

.upload-summary-card.error {
  background-color: var(--color-error-light);
}

.upload-summary-card.error .upload-summary-card-icon {
  color: #F44336;
  fill: #F44336;
}

.uploaded-data-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #FBFAFA;
  margin-bottom: 1rem;
  border-radius: 6px;
}

.data-filter-button {
  background-color: var(--color-primary-main);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  color: white;
  fill: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  min-width: 175px;
  cursor: pointer;
}

.data-filter-button.correct {
  background-color: var(--color-success-main);
}

.data-filter-button.incorrect {
  background-color: var(--color-error-main);
}

.data-filter-button-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.data-filter-button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiMenu-list .MuiMenuItem-root.uploaded-data-filter-menu-item {
  background-color: var(--color-primary-main);
  color: white;
  margin: 0.25rem;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
}

.MuiMenu-list .MuiMenuItem-root.uploaded-data-filter-menu-item.success {
  background-color: var(--color-success-main);
}

.MuiMenu-list .MuiMenuItem-root.uploaded-data-filter-menu-item.error {
  background-color: var(--color-error-main);
}

.MuiMenu-list .MuiMenuItem-root.uploaded-data-filter-menu-item .MuiListItemIcon-root.uploaded-data-filter-menu-item-icon {
  color: white;
  fill: white;
  min-width: 28px;
}

.MuiMenu-list .MuiMenuItem-root.uploaded-data-filter-menu-item .MuiListItemIcon-root.uploaded-data-filter-menu-item-icon svg {
  width: 18px;
}

.uploaded-data-actions-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.MuiDialogContent-root.upload-data-dialog-content-root {
  display: flex;
  flex-direction: column;
}

.upload-data-drop-area-wrapper {
  width: 100%;
  height: 100%;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #808080;
  cursor: pointer;
  gap: 2rem;
}

.upload-data-actions-wrapper {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.MuiTable-root.invalid-formats-data-table th.MuiTableCell-root,
.MuiTable-root.invalid-formats-data-table td.MuiTableCell-root {
  border: 1px solid black;
}

.upload-data-column-filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}

.fullwidth {
  width: 100%;
}

.uploaded-data-filters-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.MuiAutocomplete-root .MuiAutocomplete-tag.MuiChip-root.device-selection-chip-root {
  max-width: 150px;
}

.MuiAutocomplete-root .MuiAutocomplete-inputRoot.device-selection-input-root {
  flex-wrap: nowrap;
}

@media(min-width: 768px) {
  .upload-data-actions-wrapper {
    flex-direction: row;
  }
}