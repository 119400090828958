.MuiDialog-root .MuiDialog-container .MuiPaper-root.secure-login-dialog-root {
  border-radius: 6px;
}

.MuiDialogContent-root.secure-login-dialog {
  background-color: #8D86C3;
  border-radius: 5px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 4rem;
}

.secure-login-dialog-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.login-email-selection-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.MuiFormControl-root .MuiInputBase-root.MuiFilledInput-root.secure-login-input-root,
.MuiFormControl-root .MuiInputBase-root.MuiFilledInput-root.secure-login-input-root input {
  border-radius: 4px;
  background-color: white;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.secure-login-actions-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label.secure-email-select-label {
  flex: 1;
  text-align: center;
  color: white;
}

.secure-login-input-wrapper {
  width: 100%;
}

.secure-login-error-wrapper {
  background-color: var(--color-error-light);
  color: var(--color-error-main);
  border: 1px solid var(--color-error-main);
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.secure-login-dialog-close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: white;
}