.device-selection-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  min-width: 175px;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  cursor: pointer;
}

.device-selection-button.light {
  background-color: var(--color-primary-light);
  color: var(--color-primary-dark);
}

.device-selection-button.dark {
  background-color: var(--color-primary-main);
  color: white;
}

.device-selection-button-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.MuiMenuItem-root.device-selection-dropdown-menu-item {
  min-width: 175px;
  color: #000000DE;
  padding: 0.5rem 1rem;
}