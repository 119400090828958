.dashboard-screen-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  --cards-border-radius: 4px;
  --cards-background-color: white;
}

.dashboard-charts-section-wrapper {
  background-color: var(--cards-background-color);
  border-radius: var(--cards-border-radius);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dashboard-data-section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dashboard-stats-section-wrapper {
  display: flex;
  gap: 1rem;
}

.dashboard-stats-section {
  flex: 1;
  background-color: var(--cards-background-color);
  border-radius: var(--cards-border-radius);
  padding: 0.5rem 1rem 1rem;
}

.vehicle-stats-wrapper {
  display: flex;
  gap: 1rem;
}

.vehicle-stats {
  flex: 1;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
}

.vehicle-stats.auth {
  background-color: #C9FFD830;
  color: var(--color-success-main);
}

.vehicle-stats.unauth {
  background-color: #F7D5A130;
  color: var(--color-warning-main);
}

.vehicle-type-counts-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.vehicle-type-count {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.vehicle-stats.auth .vehicle-type-count-icon {
  fill: var(--color-success-main);
}

.vehicle-stats.unauth .vehicle-type-count-icon {
  fill: var(--color-warning-main);
}

.dashboard-info-section-wrapper {
  /* flex-grow: 1; */
  display: flex;
  gap: 1rem;
  /* height: 0; */
}

.dashboard-device-status-section-wrapper {
  flex: 0.35;
}

.dashboard-notifications-section-wrapper {
  flex: 0.65;
}

.dashboard-device-status-section-wrapper,
.dashboard-notifications-section-wrapper {
  background-color: var(--cards-background-color);
  border-radius: var(--cards-border-radius);
  padding: 0.5rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dashboard-device-details-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  gap: 1rem;
  flex-grow: 1;
  overflow-y: auto;
  max-height: 225px;
}

.dashboard-device-details {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.dashboard-device-details-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-main);
  border-radius: 4px;
  width: 34px;
  height: 34px;
  color: white;
}

.dashboard-device-details-text {
  color: var(--color-primary-main);
  display: flex;
  flex-direction: column;
}

.dashboard-device-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #000000C2;
  font-size: 12px;
}

.dashboard-device-status svg.dashboard-device-status-icon {
  width: 12px;
  height: 12px;
}

.dashboard-notifications-list {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 225px;
}

.dashboard-notification {
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}

.dashboard-notification-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 3px;
}

.dashboard-notification.error {
  background-color: var(--color-error-light);
}

.dashboard-notification.error .dashboard-notification-icon-wrapper {
  background-color: var(--color-error-main);
}

.dashboard-notification.error .dashboard-notification-info-wrapper {
  color: var(--color-error-main);
}

.dashboard-notification.warning {
  background-color: var(--color-warning-light);
}

.dashboard-notification.warning .dashboard-notification-icon-wrapper {
  background-color: var(--color-warning-main);
}

.dashboard-notification.warning .dashboard-notification-info-wrapper {
  color: var(--color-warning-main);
}

.dashboard-filters-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cards-background-color);
  border-radius: 5px;
  padding: 1rem;
}

.dashboard-time-selection-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-main);
  color: white;
  padding: 0.5rem;
  border-radius: 3px;
  cursor: pointer;
}

.dashboard-metrics-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1.25rem;
  flex: 1;
}

.dashboard-metrics-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 0.5rem;
}

.dashboard-metrics-card-icon.info {
  background-color: var(--color-info-light);
  fill: var(--color-info-main);
}

.dashboard-metrics-card-icon.error {
  background-color: var(--color-error-light);
  fill: var(--color-error-main);
}

.dashboard-metrics-card-icon.success {
  background-color: var(--color-success-light);
  fill: var(--color-success-main);
}

.dashboard-metrics-card-icon.warning {
  background-color: var(--color-warning-light);
  fill: var(--color-warning-main);
}

.dashboard-metrics-card-text .info-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dashboard-metrics-section-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* padding: 0.5rem 1.5rem; */
  background-color: var(--cards-background-color);
  border-radius: var(--cards-border-radius);
}

.line-chart-no-data-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-chart-wrapper {
  background-color: var(--cards-background-color);
  border-radius: var(--cards-border-radius);
  padding: 1rem;
  position: relative;
}