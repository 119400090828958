.nested-page-layout-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.nested-page-layout-header {
  background-color: white;
  padding: 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 1rem;
}