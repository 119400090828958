.uptime-screen-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.uptime-screen-header-wrapper {
  background-color: white;
  border-radius: 6px;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.uptime-screen-header-cards-wrapper {
  width: 100%;
  /* display: flex;
  align-items: center;
  gap: 1rem; */
}

.uptime-screen-table-wrapper {
  background-color: white;
  border-radius: 6px;
  width: 100%;
  flex-grow: 1;
  padding: 1rem;
  height: 0;
  display: flex;
  flex-direction: column;
}

.uptime-table-actions-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.uptime-table-download-button {
  background-color: var(--color-primary-main);
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.uptime-screen-header-card {
  background-color: white;
  padding: 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 100%;
}

.uptime-screen-header-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  width: 40px;
  height: 40px;
}

.uptime-screen-header-card-icon svg.rotate {
  transform: rotate(180deg);
}

.uptime-screen-header-card-icon.success {
  background-color: var(--color-success-light);
  color: var(--color-success-main);
}

.uptime-screen-header-card-icon.error {
  background-color: var(--color-error-light);
  color: var(--color-error-main);
}

.uptime-screen-header-card-text {
  overflow: hidden;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator.uptime-table-column-separator {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.uptime-table-column-header {
  background-color: #D7D1FF;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.uptime-table-column-header:not(:last-child):not(:nth-last-child(2))::after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  height: 60%;
  background-color: #00000026;
}

.MuiDataGrid-root.uptime-table-root {
  border-radius: 5px;
}