.society-configuration-screen-wrapper {
  flex-grow: 1;
  background-color: white;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.society-configuration-screen-tabs-wrapper {
  width: 100%;
  background-color: var(--color-primary-light);
  padding: 0.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.society-configuration-screen-tab-content-wrapper {
  flex-grow: 1;
}

/* BUILDING CONFIGURATION COMPONENT */
.building-configuration-component-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buildings-list-wrapper {
  width: 100%;
  display: flex;
  align-items: stretch;
}

.buildings-list-scroll-arrow {
  padding: 0.5rem;
  background-color: #DFE5E6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-main);
  cursor: pointer;
}

.buildings-list-scroll-arrow.left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.buildings-list-scroll-arrow.right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.buildings-list {
  flex-grow: 1;
  background-color: #F1F1F1;
  padding: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  gap: 0.75rem;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.buildings-list::-webkit-scrollbar {
  display: none;
}

.buildings-list-item {
  height: 100%;
  flex-shrink: 0;
}

.MuiButton-root.MuiButton-containedPrimary.inactive {
  background-color: #8D86C3;
}

.MuiFormControl-root .MuiInputBase-root.MuiFilledInput-root.building-input-root,
.MuiFormControl-root .MuiInputBase-root.MuiFilledInput-root.building-input-root input {
  border-radius: 4px;
}

.building-config-actions-wrapper {
  width: 100%;
  padding: 0.5rem;
  background-color: #F9F9F9;
  border-radius: 5px;
  border: 1px solid #00000014;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.buildings-flats-preview-wrapper {
  background-color: #F9F9F9;
  padding: 0.5rem;
  border-radius: 5px;
  flex-grow: 1;
  height: 0;
  overflow-y: auto;
}

.buildings-flats-preview {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.building-flats-preview-item {
  background-color: #D7D1FF;
  border-radius: 5px;
  min-height: fit-content;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #5B4DBE;
}

.building-flats-preview-item.inactive {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.building-flats-preview-item .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media(min-width: 728px) {
  .buildings-flats-preview {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media(min-width: 992px) {
  .buildings-flats-preview {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media(min-width: 1200px) {
  .buildings-flats-preview {
    grid-template-columns: repeat(9, 1fr);
  }
}

@media(min-width: 1600px) {
  .buildings-flats-preview {
    grid-template-columns: repeat(12, 1fr);
  }
}

/* CUSTOM AREAS COMPONENT */
.custom-area-component-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}