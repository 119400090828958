.event-logs-screen-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.event-logs-screen-header-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 6px;
  background-color: white;
}

.event-logs-table-section {
  flex-grow: 1;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.event-logs-table-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MuiFormControl-root .MuiInputBase-root.event-logs-search-input {
  border-radius: 4px;
  min-width: 300px;
  background-color: #F4F3FF;
  /* color: #5B4DBE75; */
}

.MuiFormControl-root .MuiInputBase-root.event-logs-search-input .MuiInputBase-input::placeholder,
.MuiFormControl-root .MuiInputBase-root.event-logs-search-input .MuiInputBase-input::-webkit-input-placeholder {
  color: #5a4dbea5;
}

.event-logs-table-actions-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.event-logs-table-wrapper {
  flex-grow: 1;
  height: 0;
  display: flex;
  flex-direction: column;
}

.MuiDataGrid-root.event-logs-table-root {
  border-radius: 5px;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator.event-logs-table-column-separator {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.event-logs-table-column-header:not(:last-child):not(:nth-last-child(2))::after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  height: 60%;
  background-color: #00000026;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.event-logs-table-column-header {
  background-color: #D7D1FF;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.event-logs-table-column-header.authorized {
  background-color: #D7E8D8;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.event-logs-table-column-header.blacklisted {
  background-color: #FEE8E8;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.event-logs-table-column-header.unauthorized {
  background-color: #FFEADA;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.event-logs-table-column-header.visitor {
  background-color: #E7F1FF;
}

.MuiDataGrid-root .MuiDataGrid-cell.blacklisted {
  background-color: #FEE8E8;
}

.event-vehicle-type-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding-right: 0.25rem;
}

.event-type-identifier-color {
  height: 65%;
  width: 10px;
  border-radius: 2px;
}

.event-type-identifier-color.authorized {
  background-color: var(--color-success-main);
}

.event-type-identifier-color.blacklisted {
  background-color: var(--color-error-main);
}

.event-type-identifier-color.unauthorized {
  background-color: var(--color-warning-main);
}

.event-type-identifier-color.visitor {
  background-color: var(--color-info-main);
}

/* EVENT LOG DETAILS DIALOG */
.log-details-dialog-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.log-evidence-image-wrapper {
  flex-grow: 1;
  position: relative;
}

.log-change-arrow {
  background-color: var(--color-primary-light);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.log-change-arrow.disabled {
  background-color: rgba(0, 0, 0, 0.12);
  cursor: default;
}

.log-details-tabs-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.log-details-tab-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  padding: 0.25rem 0;
}

.log-details-tab-button.active {
  background-color: var(--color-primary-light);
}

.log-details-tab-button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.log-details-tab-button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.log-detail-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  /* padding: 0 0.25rem; */
}

.log-detail-icon {
  padding: 0.5rem;
  background-color: var(--color-primary-light);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.log-history-vehicle-number-wrapper {
  width: 100%;
  background-color: var(--color-primary-light);
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  border-radius: 5px;
}

.log-history-auth-type {
  height: 24px;
  width: 10px;
  background-color: var(--color-warning-main);
  border-radius: 2px;
}

.log-history-vehicle-type-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
}

.log-history-count-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  border-radius: 5px;
}

.log-history-count-wrapper.entry {
  background-color: var(--color-success-light);
}

.log-history-count-wrapper.exit {
  background-color: var(--color-error-light);
}

.log-history-count-type {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator.log-history-table-column-separator {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.log-history-table-column-header {
  background-color: var(--color-primary-light);
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.log-history-table-column-header:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  height: 60%;
  background-color: #00000026;
}

.MuiDataGrid-root .MuiDataGrid-virtualScroller.log-history-table-virtual-scroller {
  min-height: 36px;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label.column-checkbox-item-label {
  text-transform: capitalize;
}

.MuiFormControlLabel-root.column-checkbox-item-root {
  width: 100%;
  margin-right: 0;
  height: 100%;
  border: 1px solid #ACACAC;
  border-radius: 5px;
}